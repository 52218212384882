<template>
    <div>
        <div class="main">
            <div class="title">
                订单管理
            </div>

            <div class="nav">
                <div :class="active_index == index?'active_item':'nav_item'" v-for="(item,index) in nav" :key="index" @click="change_type(index)">
                     {{item}}
                </div>
            </div>

            <div class="status">
                 <div>待支付</div>
                 <div></div>
                 <div>已支付</div>
                 <div></div>
                 <div>已完成</div>
            </div>


            <div class="content">

                <div class="vertical">

                    <div class="order" v-for="(item,index) in not_order" :key="index">
                        <div class="order-desc">
                            <img src="../assets/order.png" mode="">
                            <b>订单号：</b>{{item.items[0].order_id}}
                        </div>
                        <div class="order-number">
                            <b>下单时间</b>：{{new Date(parseInt(item.items[0].utime)*1000).toLocaleDateString().replace(/:\d{1,2}$/,' ') }}
                        </div>
                        <div class="order-number">
                            <b>服务内容</b>：{{item.items[0].name}}
                        </div>
                        <div class="order-status">
                            <div>
                                订单状态：未支付
                            </div>
                        </div>
                    </div>
                    

                </div>
                <div class="vertical">

                    <div class="order" v-for="(item,index) in ok_order" :key="index">
                        <div class="order-desc">
                            <img src="../assets/order.png" mode="">
                            <b>订单号：</b>{{item.items[0].order_id}}
                        </div>
                        <div class="order-number">
                            <b>下单时间</b>：{{new Date(parseInt(item.items[0].utime)*1000).toLocaleDateString().replace(/:\d{1,2}$/,' ') }}
                        </div>
                        <div class="order-number">
                            <b>服务内容</b>：{{item.items[0].name}}
                        </div>
                        <div class="order-status">
                            <div>
                                订单状态：已支付
                            </div>
                            <div class="cancel_order" @click="complete(item.items[0].order_id)">
                                完成咨询
                            </div>
                        </div>
                    </div>

                </div>
                <div class="vertical">

                    <div class="order" v-for="(item,index) in complete_order" :key="index">
                        <div class="order-desc">
                            <img src="../assets/order.png" mode="">
                            <b>订单号：</b>{{item.items[0].order_id}}
                        </div>
                        <div class="order-number">
                            <b>下单时间</b>：{{new Date(parseInt(item.items[0].utime)*1000).toLocaleDateString().replace(/:\d{1,2}$/,' ') }}
                        </div>
                        <div class="order-number">
                            <b>服务内容</b>：{{item.items[0].name}}
                        </div>
                        <div class="order-status">
                            <div>
                                订单状态：已完成
                            </div>
                        </div>
                    </div>
                </div>

            </div>




        </div>



        <div class="mask" v-show="show_mask" @click.self="show_mask = !show_mask">
            <div class="popup">
            <img src="../assets/close.png" class="close" alt="" @click="show_mask = !show_mask">    
                <div class="img_style">
                    <div class="phone">致电客服：15588513282</div>
                </div>
            </div>
        </div>

        
    </div>
</template>
<script>
export default {
    data(){
        return{
            nav:['标书审查','律师/专家','联系客服'],
            active_index:0,
            show_mask:false,
            searchData:{
					order_show:1,//1:标书订单 2：资讯订单,
					// status:1,
					page:'',
					limit:'',
					keyword:''
            },
            not_order:[],
            ok_order:[],
            complete_order:[]
        }
    },
    watch:{
        show_mask(val){
            if(val){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = ''
            }
        }
    },
    created(){
        this.loadData()
    },
    methods:{
        complete(item){
            this.$api.userpost('order.confirm',{order_ids:item},(res)=>{
                if(res.data.status){
                    this.not_order = []
                    this.ok_order = []
                    this.complete_order = []
                    this.loadData()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        loadData(){
				this.$api.userpost('order.getorderlist',this.searchData,(res)=>{
					if(res.data.status){
                        let order_list = ''
                        order_list = res.data.data.list
                            for(let i = 0 ; i < order_list.length; i++){
                                if(order_list[i].pay_status == 1){
                                    this.not_order.push(order_list[i])
                                }else if(order_list[i].pay_status == 2 && order_list[i].status ==1){
                                    this.ok_order.push(order_list[i])
                                }else if(order_list[i].status == 2){
                                    this.complete_order.push(order_list[i])
                                }
                            }
					}else{
						this.$message.error(res.data.msg)
					}
				})
			},
        change_type(val){
            this.active_index = val
            if(val == 0){
                this.searchData.order_show = 1
                this.not_order=[]
                this.ok_order=[]
                this.complete_order=[]
                this.loadData()
            }else if(val == 1){
                this.not_order=[]
                this.ok_order=[]
                this.complete_order=[]
                this.searchData.order_show = 2
                this.loadData()
            }else if(val == 2){
                 this.show_mask = true
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .main{
        padding: 30px;
        margin: 40px auto;
        width: 1140px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
    .title{
       text-align: left;
       font-size: 26px; 
       color: #144591;
    }
    .nav{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .nav_item{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 360px;
            height: 80px;
            background: #FFFFFF;
            color:#666;
            opacity: 1;
            border-radius: 10px;
            font-size: 22px;
        }
        .active_item{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 360px;
            height: 80px;
            background: #144591;
            opacity: 1;
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 22px;
        }
    }

    .status{
        margin-top: 30px;
        width: 1140px;
        height: 50px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #666;
        justify-content: space-evenly;
        div:nth-child(2){
            width: 1px;
            height: 38px;
            background: #D8D8D8;
        }
        div:nth-child(4){
            width: 1px;
            height: 38px;
            background: #D8D8D8;
        }
    }
    
    .content{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .vertical{
            width: 360px;
        }
    }







    .order{
		border: 1px #fff solid;
		width: 360px;
		height: 150px;
		background-color: #FFF;
		border-radius: 10px;
        margin-bottom: 30px;
	}
	
	.order-desc{
		margin: 15px 0 0 10px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #666;
	}
	.order-desc img{
		height: 17px;
		width: 14px;
		margin-right:11px;
	}
	.order-number{
		width: 100%;
		/* #ifdef MP-WEIXIN */
		display: flex;
		/* #endif */
		
		margin: 10px 0 0 34px;
		font-size: 14px;
		color: #666;
	}
	.order-status{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 20px 0 0 34px;
		font-size: 14px;
		color: #666;
		font-weight: bold;
	}
	
	.cancel_order{
		line-height: 26px;
		text-align: center;
		font-size: 14px;
		color: #144591;
		margin-right: 10px;
		width: 80px;
		height: 26px;
		background: #FFFFFF;
		border: 1px solid #144591;
		border-radius: 13px;
	}

     .mask{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: .3);
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .popup{
      position: relative;
      width: 786px;
        height: 533px;
        background: #FFFFFF;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 30px;
  }

  .img_style{
      position: relative;
      height: 430px;
      width: 462px;
      margin: 46px 0 0 168px;
      background-image: url('../assets/service.png');
  }
  
  .close{
      height: 36px;
      width: 36px;
      position: absolute;
      top: 24px;
      right: 24px;
  }

  .phone{
      font-size: 30px;
      color: #FFF;
      position: absolute;
      bottom: 9px;
      left: 74px;
  }

</style>